<script>
import { ref, provide } from 'vue'

export default {
  name: 'health-layout-desktop',

  setup() {
    const hasShadow = ref(false)

    provide('hasShadow', hasShadow)

    function onScroll(e) {
      hasShadow.value = e.target && e.target.scrollTop > 0
    }

    return {
      hasShadow,
      onScroll,
    }
  },
}
</script>

<template>
  <div class="flex h-full w-full">
    <div class="grow bg-dark" />
    <div class="flex h-full w-full max-w-full shrink-0 grow overflow-hidden">
      <div
        class="relative flex h-full w-full flex-col sm:overflow-hidden sm:rounded-tl-3xl md:mr-5 md:rounded-t-3xl"
      >
        <div
          class="pointer-events-none absolute top-0 z-10 h-[30px] w-full rounded-inherit shadow-inner transition duration-300"
          :class="hasShadow ? 'opacity-100' : 'opacity-0'"
        />
        <div
          class="grow rounded-inherit sm:h-full sm:overflow-y-auto sm:border sm:bg-eonx-neutral-50"
          @scroll="onScroll"
        >
          <div
            class="min-h-full overflow-hidden rounded-t-3xl bg-eonx-neutral-50"
          >
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
